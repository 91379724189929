// reference for price table
// https://www.w3tweaks.com/css/html-and-css-pricing-tables.html

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.css']
})
export class PricingTableComponent implements OnInit {
  
  @Input('subscription-title') subscriptionTitle: string;
  @Input('sub-title') subTitle: string;
  @Input() price: string;
  @Input() duration: string;
  @Input() list: any;

  @Output() selected: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  
  onClickChoose(){
    this.selected.emit({subscriptionTitle: this.subscriptionTitle, price: this.price, duration: this.duration })  
  }

}
