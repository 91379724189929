import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui'
import { FirebaseAuthService } from '../auth/firebase-auth.service';

@Component({
  selector: 'firebase-ui',
  template: ''
})
export class FirebaseUIComponent implements OnInit {

  constructor(
    private _auth: FirebaseAuthService,
  ) {
    
    
    
  }  
  

  ngOnInit() {
    var uiConfig = {
        //signInSuccessUrl: '/picks',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
          signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // Process result. This will not trigger on merge conflicts.
            // On success redirect to signInSuccessUrl.
            return false;
          }
        }
      };
      
    let ui = firebaseui.auth.AuthUI.getInstance()
    if (!ui) {
      //ui = new firebaseui.auth.AuthUI(firebase.auth())
      ui = new firebaseui.auth.AuthUI(this._auth.afAuth.auth)          
    }
    ui.start('firebase-ui', uiConfig)
  }

}