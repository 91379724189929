import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../auth/firebase-auth.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  
  email: string;
  resetMessage: boolean = false;
  
  constructor(
    private _auth: FirebaseAuthService
  ) { }
  ngOnInit() {
  }
  
  reset(){
    this._auth.resetPassword(this.email)
      .then(()=>{
        this.resetMessage = true;
      });
  }
}
