import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FirebaseAuthService } from '../auth/firebase-auth.service';

declare var jQuery:any;

@Component({
  selector: 'pregame-show',
  templateUrl: './pregame-show.component.html',
  styleUrls: ['./pregame-show.component.css']
})
export class PregameShowComponent implements OnInit {

  subscription: Subscription;
  nums = [1,2]

  constructor(
    private _auth: FirebaseAuthService
  ) { }
  ngOnInit(
    
  ) {
  }
  
  onClickAboutUsButton(){}  


  ngAfterViewInit(){
    jQuery(document).ready(function(){  
      // for(var i=0 ; i< m.length ; i++) {
      //   $('<div class="item"><img src="'+m[i]+'"><div class="carousel-caption"></div>   </div>').appendTo('.carousel-inner');
      //   $('<li data-target="#carousel-example-generic" data-slide-to="'+i+'"></li>').appendTo('.carousel-indicators')
    
      // }
      // $('.item').first().addClass('active');
      // $('.carousel-indicators > li').first().addClass('active');
      // $('#carousel-example-generic').carousel();

      console.log('inside jQuery');
    });

    this.subscription = this._auth.user.subscribe(item => {
      if (item) {

        console.log(item);
      }
    });

  }
}
