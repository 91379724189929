import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router'
import { FirebaseAuthService } from '../auth/firebase-auth.service';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  
  subscription:Subscription;
  
  email: string ="";
  password: string ="";
  warningMessage: string = '';
  googleLogin = false;
  sign_in_with_email: boolean = false;
  new_user: boolean = true;
  emailCheck: boolean = false;
  forgotPassword: boolean = false;

  constructor(
    private _auth: FirebaseAuthService,
    private _router: Router
  ) {}  
  
  ngOnInit() {
    
    this.subscription = this._auth.user.subscribe(item => {
      
      //console.log(item);
      //if (item && (item.providerData[0].providerId !=='password' || item.emailVerified)) {
      if (item && item.emailVerified) {
      //if (item) {
        //console.log(item.providerData[0].providerId);
        //if (item.firstTime)
          this._router.navigate(['/picks']);          
        //else
        //  this._router.navigate(['/iebd-sb']);
      //} else if (item) {
      //  this._auth.signOut();
      }
      
    });
  }
  
  signInWithGoogle(){
    this._auth.signInWithGoogle().then(this.onGoogleSignInSuccess);
  }
  
  private onGoogleSignInSuccess(res): void {
  }
  
  signInWithEmail() {
    this.sign_in_with_email = true;
    this.new_user = false;
    this.warningMessage = "";
    this.email = "";
  }
  
  goNext(){
    
    if (this.email.trim() == "") {
      return;
    }
    
    this._auth.fetchSignInMethodsForEmail(this.email)
    .then((provider)=>{
      
      if (provider[0] == 'password') {
        this.emailCheck = true;
        this.warningMessage = "";        
      } else if (provider[0] == 'google.com') {
        this.googleLogin = true;
        this.sign_in_with_email = false;
      } else {
        this.warningMessage = "your email address is not found";
        this.new_user = true;        
        //this._router.navigate(['/signup']);
      }
    })
    .catch(err =>{
      this.warningMessage = err;
    })
    
  }
  
  cancel() {
    this.sign_in_with_email = false;
    this.new_user = true;                  
  }
  
  signInWithEmailAndPassword() {
    
    this._auth.fetchSignInMethodsForEmail(this.email).then((provider)=>{
      
      if (provider[0] == 'password') {
        this._auth.signInWithEmailAndPassword(this.email,this.password)
          .catch(err=> {
            this.warningMessage = err;
            this.forgotPassword = true;
          })
          .then((res:any)=>{
            if (res.emailVerified)
              this._router.navigate(['/picks']);
            else
              this.warningMessage = "Please Verify your email address."
              
            
            console.log(res.emailVerified);
          })
      } else if (provider[0] == 'google.com') {
        this.googleLogin = true;
      } else {
        console.log(provider);
      }
    })
  }
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }  
}
