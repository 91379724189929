import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { PaymentService } from '../payment.service';
import { Source, Customer } from '../models';

@Component({
  selector: 'user-default-source',
  templateUrl: './user-default-source.component.html',
  styleUrls: ['./user-default-source.component.css']
})
export class UserDefaultSourcesComponent implements OnInit {

  customer$: Observable<Customer>;
  @Input() price: number;
  @Input()  canSelect: boolean;
  @Input() selected: boolean = false;
  @Input() duration: string = "";
  @Input("subscription-title") subscriptionTitle: string = "";
  @Output() isSource = new EventEmitter<boolean>();
  @Output() paid = new EventEmitter<any>();
  //@Output() selectedSource = new EventEmitter<Source>();
  selectedSource: string;
  
  defBrand: string = "";
  defFunding: string = "";
  defLast4: string ="";
  defExpMonth: string = "";
  defExpYear: string = "";
  
  //selected: boolean = false;

  constructor(private pmt: PaymentService) { }

  ngOnInit() {
    this.customer$ = this.pmt.getCustomer();
    this.customer$.subscribe(val=> {

      const l = val.sources.data.length
      this.selected = l !== 0? true : false;
      if (this.selected) {
        const s = val.sources.data[l-1];
        this.defBrand = s.card.brand;
        this.defFunding = s.card.funding;
        this.defLast4 = s.card.last4;
        this.defExpYear = s.card.exp_year;
        this.defExpMonth = s.card.exp_month;
        this.selectedSource = val.sources.data[l-1].id;
      }
      //this.isSource.emit(val.sources.data.length !== 0? true : false)
    });
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (this.selected) {    
      if (changes.selected.currentValue) {
        this.customer$ = this.pmt.getCustomer();
        this.customer$.subscribe(val=> {
          const l = val.sources.data.length;
          this.selected = l !== 0? true : false;
          const s = val.sources.data[l-1];
          this.defBrand = s.card.brand;
          this.defFunding = s.card.funding;
          this.defLast4 = s.card.last4;
          this.defExpYear = s.card.exp_year;
          this.defExpMonth = s.card.exp_month;
          this.selectedSource = val.sources.data[l-1].id;
        });
      }
    }
  }

  clickHandler(source: Source) {
    this.selected = true;
    //this.selectedSource.emit(source)
    this.selectedSource = source.id;
  }
  
  deleteHandler(source:Source) {

    const a = this.pmt.deleteSource(source);
    a.subscribe(data =>{
      console.log(data);
    })
  }
  
  paymentHandler(){
    console.log(this.selectedSource);
    let action = this.pmt.testCreateCharge(this.selectedSource, this.price, this.subscriptionTitle);
    
    action.subscribe(
      data => {
        //this.result = data;
        //this.stripeResult.emit(data);
        //this.loading = false;
        console.log(data);
        this.paid.emit({message:'paid'});
      },
      err => {
        //this.result = err;
        //this.loading = false;
        //console.log('err');        
        //console.log(err);
      }
    );    
  }

}
