import { Component, OnInit, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router'
import { FirebaseAuthService } from '../auth/firebase-auth.service';


declare var $: any;


@Component({
  selector: 'picks',
  templateUrl: './picks.component.html',
  styleUrls: ['./picks.component.css']
})
export class PicksComponent implements OnInit {

	subscription: Subscription
	isSignedIn: boolean = false;
	isPaid: boolean = true;
  isTest: boolean = false;

  LVDate: string = "";

  picks: any = [];
  constructor(
    private _auth: FirebaseAuthService,
    private _router: Router,
    private _zone: NgZone
  ){
  }

  ngOnInit() {
    
    //if(env === 'prod') { // assuming you have env variable configured
    //  window.console.log = function(){};
    //}  
  }
  
  ngAfterViewInit(){

    //$.getJSON('https://www.fedgis.com/specumetrixNAG/specumetrixNAG.aspx?a=picks',(data) => {
		/*
    $.getJSON('./assets/demo/data.json',(data) => {
      const lastItem = data[data.length - 1];
			this.LVDate = new Date(lastItem.LVDate).toLocaleString();
			
			if (this.isSignedIn)
				this.picks = data;
			else
				this.picks = [data[0]];			
				
				this.picks.forEach((pick) =>{
					pick.team1 = pick["Team 1"].toLowerCase();
					pick.team2 = pick["Team 2"].toLowerCase();
					pick.record1 = pick["Record 1"];
					pick.record2 = pick["Record 2"];				
				
					//pick.cssClass = "";
					//if(pick.Final && pick.Winner == pick.Pick){
					//	pick.cssClass = "winner";
					//}else if(pick.Final && pick.Winner != pick.Pick){
					//	pick.cssClass = "loser";
					//}
				
					//calculate recency
					//var pickDate = new Date(pick.Date);
					//var now = new Date();
					//var elapsed = now - pickDate;
					//if(elapsed < 60 * 1000){
					//	pick.cssClass += " just-updated";
					//}
				});

    });
		*/
    this.subscription = this._auth.user.subscribe(item => {
      if (item) {
        if (item.subscriptions !== undefined && item.subscriptions.expiration_time > (new Date().getTime())){
        	this.isPaid = true;

	        console.log(item.subscriptions.expiration_time);
	        console.log(new Date().getTime());
        } else {
          //this.isPaid = false;        	
          this.isPaid = true;
        }
        
				this.getPicksData();        	        
      }
    });

    

  }
  
  getPicksData(){
    const self = this;

    try {
      $.connection.hub.url = 'https://www.fedgis.com/specumetrixsignalr/signalr/';
    } catch (error) {
      console.log('test2');
      return;
    }
    
    let steertheline = $.connection.specumetrixHub;
    // Create a function that the hub can call to broadcast messages.
    steertheline.client.broadcastPicks = function (picks) {
    	//var now = new Date();
    	//console.log(now.toLocaleTimeString(), 'signalR picks data received', JSON.parse(picks));
    	//handlePickData(JSON.parse(picks));

      self._zone.run(()=>{
      	const data = JSON.parse(picks);

        const lastItem = data[data.length - 1];
  			self.LVDate = new Date(lastItem.LVDate).toLocaleString();
  			console.log(data);
  			console.log(self.isPaid);
        self.picks = data;

				self.picks.forEach((pick) =>{
					pick.team1 = pick["Team 1"].toLowerCase();
					pick.team2 = pick["Team 2"].toLowerCase();
					pick.record1 = pick["Record 1"];
					pick.record2 = pick["Record 2"];			

          const d = new Date(pick.Date);
					pick.dateString = d.toLocaleDateString();
					
					const tlts = d.toLocaleTimeString().split(" ");
					pick.timeString = tlts[0].slice(0,-3) + " " + tlts[1];
					//pick.timeString = d.toLocaleTimeString(); //('en-GB').slice(0, -3);
					pick.dayString =  d.getDay();
					
					if (pick.posteam == 1) { 
					  pick.ballimg = 'assets/images/ball_line_right.png';
	  				pick.ballmargin = (parseInt(pick.xcoord)*0.8) + '%';
	  				pick.ballstate = true;
					} else if (pick.posteam == 2) {
					  pick.ballimg = 'assets/images/ballleft.png';
  					pick.ballmargin = (parseInt(pick.xcoord)*0.8 - 9) + '%';
	  				pick.ballstate = true;  					
					} else {
	  				pick.ballstate = false;					  
					}

				});
      });
    	
    };
    $.connection.hub.start();
  	
  }
  
  
  
  
  getCategory(c){
		if (c == 1)
			return 'FREE';
    else if (c == 2)
			return 'RECOMMENDED';
    else if (c == 3)
			return 'TOO-CLOSE-TO-CALL';
  }
  
  getGameStatus(state) {
    if (state == 'post')
      return 'Green_Dot';
    else if (state == 'in')
      return 'Yellow_Dot';
		else
      return 'Red_Dot';
  }
  
  getThumb(Final,Winner,Pick){
		if (Final == true && Winner == Pick)
		  return 'thumbup';
		else if (Final == true && Winner != Pick)
		  return 'thumbdown';
  }

  getDay(num) {

    switch (num) {
      case 0: return 'SUNDAY';
      case 1: return 'MONDAY';
      case 2: return 'TUESDAY';
      case 3: return 'WEDNESDAY';
      case 4: return 'THURSDAY';
      case 5: return 'FRIDAY';
      case 6: return 'SATURDAY';  
    }
  }
  
  onSubscribe(){
  	this._router.navigate(['/subscription']);
  }
  
  
  removeSubscription(){
    this._auth.removeSubscription();  
  }
  
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
    try {
      $.connection.hub.stop();
    } catch (error) {
      console.log(error);
    }
  }  
  
}
