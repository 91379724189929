import { Component, OnInit, NgZone } from '@angular/core';
// import { SelectorFlags } from '@angular/core/src/render3/interfaces/projection';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { FirebaseAuthService } from '../auth/firebase-auth.service';
  import 'rxjs/add/operator/first';
  import 'rxjs/add/operator/map';
  import 'rxjs/add/operator/toPromise';

declare var $: any;

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit {

  games: any = [];
  uid: string;
  buttonText = 'change'; // 'change or save
  gameWeek = '215';
  weekName = 'regular week 15';
  totalPoints = 0;
  
  initialPoints = 100;
  remainingPoints = 0;

  constructor(
    private _zone: NgZone,
    private _afs: AngularFirestore,
    private _auth: FirebaseAuthService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this._auth.user.subscribe(data => {
      //if (data.subscriptions.expiration_date == '2019:2:10:12:00')
      //  this.purchaseable = false;
      //console.log(data);
      this.uid = data.uid;

      this.checkDocument(this.gameWeek);
    });
  }

  async checkDocument(week: string){
    const weekRef = this._afs.doc(`users/${this.uid}/predictions/2020/weeks/${week}`);
    const tmp = await weekRef.valueChanges().first().toPromise();
    
    if (tmp) {
      console.log(tmp);
      
    } else {
      console.log('undefined');
      weekRef.set({'name': this.weekName});
    }

    this.getPicksData(tmp);
  }

  getPicksData(wins){
    
    const self = this;
    // $.connection.hub.url = 'https://www.fedgis.com/specumetrixsignalr/signalr/';
    // let steertheline = $.connection.specumetrixHub;
    // // Create a function that the hub can call to broadcast messages.
    // steertheline.client.broadcastPicks = function (picks) {
    	//var now = new Date();
    	//console.log(now.toLocaleTimeString(), 'signalR picks data received', JSON.parse(picks));
    	//handlePickData(JSON.parse(picks));

    //$.getJSON('./assets/demo/data_2020_rw14.json',(data) => {
    $.getJSON('https://www.fedgis.com/specumetrixNAG/specumetrixNAG.aspx?a=picks',(data) => {
      //console.log(picks);
      self._zone.run(()=>{
      	//const data = JSON.parse(picks);

        const lastItem = data[data.length - 1];

        console.log(data);
        self.totalPoints = 0;
        self.games = data;
        self.remainingPoints = self.initialPoints;
        console.log(self.remainingPoints);
				self.games.forEach((game, index) =>{
				 	game.team1 = game["Team 1"].toLowerCase();
          game.team2 = game["Team 2"].toLowerCase();
           
          game.score1 = game["Score 1"];
          game.score2 = game["Score 2"];

          game.index = 'game' + index;

          game.disabled = true;
          

          if (wins != undefined && wins.predictions != undefined) {
            for (let item of wins.predictions) {
              if (item['SeqNo'] == game['SeqNo']) {
                game.selected = (item['selected'] != undefined) ? item['selected'] : 0;
                game.bid = (item['bid'] != undefined) ? item['bid'] : 0;
                if (game.selected == game['Winner']) {
                  self.totalPoints += game['bid'];
                }
              }
            }
            self.remainingPoints -= game.bid;
          }

          
          
        });
        console.log(self.remainingPoints);
      });
    });
    // };
    // $.connection.hub.start();
  	
  }

  ngOnDestroy(){
    //$.connection.hub.stop();
  }  

  save(){

    if (this.buttonText == 'save') {
      this.buttonText = 'change';

      this.games.forEach(game=>{
        game.disabled = true;
      });

      this.saveDataToFirebase();
    } else {
      this.buttonText = 'save';

      const current = new Date().getTime();
      console.log(current);

      this.games.forEach(game=>{
        if (game.Date > current) {
          game.disabled = false;
        }
      });
    }


  }

  saveDataToFirebase(){

    const predictions = this.games.map((item)=>{
      return {
        "selected": item['selected'] != undefined ? item['selected'] : 0,
        "SeqNo": item['SeqNo'],
        "bid": item['bid']
      };
    });
    predictions.sort((a, b) => a.SeqNo < b.SeqNo ? -1 : a.SeqNo > b.SeqNo ? 1 : 0)
    console.log(predictions);

    const weekDoc = this._afs.doc(`users/${this.uid}/predictions/2020/weeks/${this.gameWeek}`);
    weekDoc.update({predictions: predictions}).then(()=>console.log('success'));
  }

  cancel(){
    this.buttonText = 'change';
    const weekRef = this._afs.doc(`users/${this.uid}/predictions/2020/weeks/${this.gameWeek}`);
    weekRef.valueChanges().first().toPromise().then(wins=>{

      this.games.forEach(game =>{
        if (wins != undefined && wins['predictions'] != undefined) {
          for (let item of wins['predictions']) {
            if (item['SeqNo'] == game['SeqNo']) {
              game.selected = (item['selected'] != undefined) ? item['selected'] : 0;
            }
          }
        }

        game.disabled = true;
      });

    });
  }

  bidChange(bid){
    console.log(bid);

    this.remainingPoints = this.initialPoints;
    this.games.forEach(game=>{

      if (game.bid == null) {
        game.bid = 0;
      }
      this.remainingPoints -= game.bid;
    });
    
  }

}
