import { Component, OnInit, AfterViewInit } from '@angular/core';

import * as d3 from 'd3';

@Component({
  selector: 'track-record',
  templateUrl: './track-record.component.html',
  styleUrls: ['./track-record.component.css']
})
export class TrackRecordComponent implements OnInit {
	
	charts = [{
		id: "track-record-weekly-chart",
		metric: "success",
		curveTension: 1,
		fillArea: true
  }, {
 		id: "track-record-cumulative-chart",
 		metric: "seasonsuccess",
 		curveTension: .5,
 		fillArea: false
  }];
  
  
	data: any = [];  
  trackRecords: any = [];

  constructor() { }
  ngOnInit() {
  }
  
  ngAfterViewInit(){
	  

		var dataUri = "https://www.fedgis.com/specumetrixnag/specumetrixnag.aspx?a=br549";
		//var dataUri = "./assets/data/track-record-2018.json";
	  d3.json(dataUri).then((data) => {
	  	
	  	this.data = data;
	  	
		  //setup empty array for each pick type
			var trackRecordArrays = data[0].picks.map(function(pick) {
	  		return [];
	  	});

		  //fill pick type arrays
	  	data.forEach(function(week) {
		  	week.picks.forEach(function(pick, i) {
	  			trackRecordArrays[i].push({
	  				weekno: week.weekno,
	  				success: pick.success,
	  				seasonsuccess: pick.seasonsuccess,
		  			type: pick.type
	  			});
  			});
	  	});

		  //transform arrays into separate series
			//var trackRecords = [];
  		trackRecordArrays.forEach((trackRecordArray) => {
  			//exclude "Too Close to Call" pick type
  			if(trackRecordArray[0].type != "Too Close to Call"){
  				this.trackRecords.push({
  					type: trackRecordArray[0].type,
  					values: trackRecordArray.map(function(pick) {
  						return { weekno: pick.weekno, success: pick.success, seasonsuccess: pick.seasonsuccess}
  					})
  				});
  			}
  		});
  	
  	
	    this.drawCharts();
	  })
	  .catch(()=>{});
		
    d3.select(window).on("resize", ()=>{
	    var s = d3.selectAll('svg');
  	  s = s.remove();
    	this.drawCharts();  	  
    });

  }
  
  drawCharts(){

	

		//.domain(['Specumetrix Picks', 'Pick of the Week']);
	  //.domain(['Steer The Line Picks', 'Pick of the Week']);
	  
		//d3.select(window).on("resize." + container.attr("id"), resize);	  

		

			var pickColors = d3.scaleOrdinal(['red', 'blue']);	  	
	  	var chartWidth = parseInt(d3.select('#'+this.charts[1].id).style('width'));
	  	
  		//render charts
  		//this.charts.forEach((chart) => {
  		for (let i=1; i<this.charts.length; i++)	{
				var chart = this.charts[i]
	  		var chartNode = d3.select("#" + chart.id);
		    var svg = chartNode.append('svg');	  		
	    	var margin = { top: 40, right: 20, bottom: 50, left: 70 };
    		var width = chartWidth - margin.left - margin.right;
    		var height = parseInt(chartNode.style("height")) - margin.top - margin.bottom;
    		

				var g = svg.attr('width', width + margin.left + margin.right)
    						.attr('height', height + margin.top + margin.bottom)
    					.append("g")
    						.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

				//var x = d3.scaleLinear().rangeRound([0, width]);
				var x = d3.scalePoint()
				      .domain(this.data.slice(0).reverse().map(function(d){return d.weekno}))
				      //.domain(d3.extent(this.data, function(d) { return d.weekno; }))
				      .rangeRound([0,width])

				var y = d3.scaleLinear().rangeRound([height, 0]).domain([0, 1]);

				var line = d3.line()
					.curve(d3.curveCardinal.tension(chart.curveTension))
					.x(function(d) { return x(d.weekno); })
					.y(function(d) { return y(d[chart.metric]); });
			
			
				//add all weeks to x domain
				//x.domain(d3.extent(this.data, function(d) { return d.weekno; }));
				//g.append("g")
				//	.attr("transform", "translate(0," + height + ")")
				//	.call(d3.axisBottom(x).ticks(this.data.length)); //set number of x-axis ticks same as number of weeks

	      var xAxis = d3.axisBottom(x);

		    g.append("g")
			    .attr("transform", "translate(0," + height + ")")
		    	.call(xAxis);

				g.append("g")
					.call(d3.axisLeft(y)
			        .ticks(10)
			        .tickFormat(d3.format(",.0%")))//10 y-axis ticks with % formatting

				g.append("g")
					.attr("class", "grid")
					.call(d3.axisLeft(y)
			        .ticks(2)
			        .tickSize(-width, 0, 0)
			        .tickFormat(""))//10 y-axis ticks with % formatting
			        
				g.append("text")             
		      .attr("transform",
		            "translate(" + (width/2) + " ," + (height + margin.top) + ")")
		      .style("text-anchor", "middle")
		      .text("Regular Week 1-"+(this.data.length-3));
		      
				var track = g.selectAll(".track")
					.data(this.trackRecords)
					.enter().append("g")
					.attr("class", "track");
			
				//append line
				track.append("path")
					.attr("class", "line")
					.attr("d", function(d) { console.log(d); return line(d.values); })
					.style("stroke", function(d) { return pickColors(d.type);});
			

				if(chart.fillArea){
				
					var area = d3.area()
				    .x(function(d) { return x(d.weekno); })
				    .y0(height)
				    .y1(function(d) { return y(d[chart.metric]); });
				
					//append line fill area
					track.append("path")
						.attr('fill', function(d) {	return pickColors(d.type);})
						.datum(function(d){return d.values})
			      	 .attr("class", 'area')
			      	.attr("d", area);
				}

				console.log(this.trackRecords[0].values);
				var valueLabels = g.append(g)
			  	.selectAll('text')
	    		.data(this.trackRecords[0].values)
			    .enter()
			    .append('text')
    			.attr({
			      'x': function(d, i) {
			      	console.log(d);
			        return x(d.weekno);
			      },
			      'y': function(d, i) {
			        return y(d[chart.metric]);
			      }
			    })
			    .text(function(d, i) {
      			return d[chart.metric];
			    });

				//legend
				var groupWidth = 200;
    		
    		//var legend = svg.append('g')
				var legend = g.append('g')  		
    			.attr('transform', 'translate('+(chartWidth/2-groupWidth)+',0)');
    		
		    var legendGroup = legend.selectAll('g')
		    	.attr('class', 'legend')
		        .data(pickColors.domain())
		        .enter()
		        .append('g')
		        .attr('transform', function(d, i) {
		            return 'translate(' + (i*groupWidth) + ', -30)';   
		        }); 
		    
		    legendGroup
		        .append('rect')
		        .attr('width', 10)
		        .attr('height', 10)
		        .attr('fill', function(d){
		            return pickColors(d);
		        });
		    
		    legendGroup
		        .append('text')
		        .attr('y', 10)
		        .attr('x', 12)
		        .text(function(d,i){
		            return d;
		        });
		        
				

    		// get width of container and resize svg to fit it
		    //function resize() {
		    //	console.log(this);
	        //var targetWidth = parseInt(d3.select('#'+charts[0].id).style("width"));
	        //console.log(chartNode);
	        //var targetWidth = parseInt(chartNode.style("width"));
	        
	        //console.log(targetWidth);
	        //svg.attr("width", targetWidth);
		    //}	    
			//});
  		}
  }
  

}
