import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router'
import { FirebaseAuthService } from '../auth/firebase-auth.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  subscription:Subscription;

  about_us_button_text = "More";
  constructor(
    private _auth: FirebaseAuthService,
    private _router: Router
  ) {}

  ngOnInit() {
  }

  onClickAboutUsButton() {
    if (this.about_us_button_text == 'More')
      this.about_us_button_text = 'Less';
    else
      this.about_us_button_text = 'More';
  }
  
  onClickFreePick(){
    this.subscription = this._auth.user.subscribe(item => {
      if (item) {
        this._router.navigate(['/picks']);          
      } else {
        this._router.navigate(['/signin']);
      }
    });
  }
  
  ngOnDestroy(){
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }  
  
}
