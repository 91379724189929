import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'my-purchases',
  templateUrl: './my-purchases.component.html',
  styleUrls: ['./my-purchases.component.css']
})
export class MyPurchasesComponent implements OnInit {

  constructor(){
  }

  ngOnInit() {
  }
  
}
