import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';
//import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';

// Firebase
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { FirebaseAuthService } from './auth/firebase-auth.service';
import { FirebaseUIComponent } from './auth/firebaseui.component';
import { TokenInterceptor } from './auth/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';

// payment module
import { PaymentModule } from './payment/payment.module';

// shared module
import { SharedModule } from './shared/shared.module';

// Components
import { OriginalComponent } from './original/original.component';

import { routes } from "./app.routing";
import { HomeComponent } from './home/home.component';
import { PregameShowComponent } from './pregame-show/pregame-show.component';
import { PicksComponent } from './picks/picks.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TrackRecordComponent } from './track-record/track-record.component';
import { ProfileComponent } from './profile/profile.component';
import { MyPurchasesComponent } from './my-purchases/my-purchases.component';
import { BlogComponent } from './blog/blog.component';
//import { PricingTableComponent } from './components/pricing-table/pricing-table.component';
//import { SubscriptionPageStlComponent } from './subscription-page-stl/subscription-page-stl.component';
import { SubscriptionComponent, DialogOverviewExampleDialog } from './subscription/subscription.component';
import { GamesComponent } from './games/games.component';

@NgModule({
  declarations: [
    AppComponent,
    OriginalComponent,
    HomeComponent,
    PregameShowComponent,
    PicksComponent,
    GamesComponent,
    SigninComponent,
    ResetPasswordComponent,    
    TrackRecordComponent,
    ProfileComponent,
    SignupComponent,
    FirebaseUIComponent,
    //PricingTableComponent,
    BlogComponent,
    MyPurchasesComponent,
    //SubscriptionPageStlComponent,
    SubscriptionComponent,
    DialogOverviewExampleDialog,
    GamesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    //CommonModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    PaymentModule,
    SharedModule
  ],
  providers: [
    FirebaseAuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    
  ],
  entryComponents: [
    DialogOverviewExampleDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
