import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { PaymentService } from '../payment/payment.service';
import { Observable } from 'rxjs/Observable';
import { FirebaseAuthService } from '../auth/firebase-auth.service';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  premiumList=[
    'Ending Midnight Super Bowl',
    'SteerTheLine Predictions for the rest of the 2018 Regular Season'    
  ];

  onetimeList=[
    'Starting Midnight Monday to Midnight Monday (Eastern Time)',
    'SteerTheLine Predictions for just the current week'
  ];

  //sourceId: string;
  userSubscriptions;
  price:string="";
  stripePrice: number=0;
  seasonalPrice: number;
  weekPrice: number;
  weekSubTitle: string;
  duration:string="";
  subscriptionTitle:string = "";
  isSource: boolean;
  purchaseable: boolean = true;
  readyToPay: boolean = false;
  
  doPayment: boolean = false;
  
  //charges$: Observable<Charge[]>;
  charges$: Observable<any>;

  constructor(
    private pmt: PaymentService, 
    private auth: FirebaseAuthService,
    public dialog: MatDialog,
    private _router: Router,
  ) { }

  ngOnInit() {
    //console.log(this.auth.subscriptions.expiration_date);
    this.auth.user.subscribe(data => {
      //if (data.subscriptions.expiration_date == '2019:2:10:12:00')
      //  this.purchaseable = false;
    });
    
    this.pricingCalculator();

  }

  chosenPlan(e){
    console.log(e);
    this.doPayment = true;
    this.price = e.price;
    this.stripePrice = parseFloat(e.price) * 100;
    this.duration = e.duration;
    this.subscriptionTitle = e.subscriptionTitle;
  }
  
  back(){
    this.doPayment = false;
  }
  
  defaultSource(e){
    console.log(e);
    this.isSource = e;
  }
  
  onPaid(e) {
    if (e.message == 'paid') {
      this._router.navigate(['/picks']);
    }
  }

  openDialog(): void {
    this.readyToPay = false;    
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      //width: '250px',
      //data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.stripePrice = 0;      
      //this.stripePrice = parseFloat(this.price) * 100;
      this.readyToPay = true;
      //this.price = this.stripePrice;
    });
  }

  pricingCalculator(){
    const season_weeks = [
      {year: 2018, month: 9, day: 11, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 9, day: 18, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 9, day: 25, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 10, day: 2, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 10, day: 9, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 10, day: 16, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 10, day: 23, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 10, day: 30, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 11, day: 6, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 11, day: 13, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 11, day: 20, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 11, day: 27, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 12, day: 4, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 12, day: 11, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 12, day: 18, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2018, month: 12, day: 25, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2019, month: 1, day: 1, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2019, month: 1, day: 8, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2019, month: 1, day: 15, hours: 4, minutes: 0, seconds: 0}, 
      {year: 2019, month: 1, day: 22, hours: 4, minutes: 0, seconds: 0}       
    ];
    
    let converted = [];
    season_weeks.forEach((item) => {
      converted.push(Date.UTC(item.year,item.month-1,item.day, item.hours, item.minutes, item.seconds, 0));
    });
    
    console.log(converted);
    console.log(new Date().getTime());
    
    const t = new Date().getTime(), discountRate = 0.1;
    let price = 0;
    for (var i=0; i<converted.length; i++) {
      if (t < converted[i]) {
        price = (200 - 10*i) * (1 - discountRate);
        break;
      }
    }
    
    this.weekPrice = 9.99;
    this.weekSubTitle = `Current Week (Week ${i+1})`;
    this.seasonalPrice = price;
    
    return { week: i+1, seasonalPrice: price };
  }
  
}


@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <h4>
    Please enter your payment information below:
    </h4>
    <div style="margin: 0 auto; min-width: 400px; max-width: 600px;">
      <payment-form-stl
        [totalAmount]="stripePrice" 
        (stripeResult)="setSource($event)">
      </payment-form-stl>
    </div>
    <button type="button" style="margin-top: 20px;" (click)="onNoClick()">Cancel</button>
  `,
  styles: [`
    /deep/.cdk-overlay-container {
      z-index: 10000;
    }  
  `
  ]
  
})
export class DialogOverviewExampleDialog {
  
  sourceId: string ='';  
  stripePrice: number=0;  

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  setSource(e) {
    //console.log(e);
    //if (e.status === 'chargeable') {
    //  this.sourceId = e.id; 
    this.dialogRef.close();    
  }
  
}