import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { auth } from 'firebase/app';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';

import { Roles, User } from './interface';

@Injectable()
export class FirebaseAuthService {


  user: Observable<User>;
  uid: string;

  constructor(
    //private afAuth: AngularFireAuth,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

      //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState
      .pipe(
        switchMap(user => {
          console.log(user);
          //if (user && user.emailVerified) {
          if (user) {
            this.uid = user.uid;            
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          } else {
            return of(null)
          }
        })
    );
    
  }
  

  signInWithGoogle() {
    return this.oAuthLogin(new firebase.auth.GoogleAuthProvider());
  }
  
  signUpWithVerification(email, password, displayName) {
    
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    .then((credential: any) => {
    
      console.log(credential);
      console.log('verification');

      const user = credential.user;
      
      const data: User = {
        uid: user.uid,
        email: user.email,
        displayName: displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        providerId: credential.additionalUserInfo.providerId
      }
      
      this.updateUserDataNonProvider(data);

      this.afAuth.auth.currentUser.sendEmailVerification();
      
      return {status:'success', message:'good'};

    })
    .catch(function(error) {
      //var errorMessage = error.message;
      //console.log(error.message);
      //return {status: 'warning', message: error.message};
      return Promise.reject(error.message || error);
    });
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
      })
  }


  private updateUserData(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      
    }

    return userRef.update(data)
      .then(() => {
        // update successful (document exists)
        console.log('update');

        const docRef = this.afs.doc(`users/${user.uid}/predictions/2020`);
        docRef.valueChanges().first().toPromise().then((a)=>{
          console.log(a);
          if (a == undefined) {
            console.log('wins');
            docRef.set({'wins': 0});
          }
        });

      })
      .catch((error) => {
        // console.log('Error updating user', error); // (document does not exists);
        data['firstTime'] = true;
        userRef.set(data);
      });
  }

  private updateUserDataNonProvider(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);


    return userRef.update(user)
      .then(() => {
        // update successful (document exists)
      })
      .catch((error) => {
        // console.log('Error updating user', error); // (document does not exists);
        user['firstTime'] = true;
        userRef.set(user);
      });
  }
  
  update(data){
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${this.uid}`);
    return userRef.update(data)
  }
  
  fetchSignInMethodsForEmail(email) {
    return firebase.auth().fetchSignInMethodsForEmail(email)
    .catch((error) => {
      return Promise.reject(error.message || error);
    })
  }
  
  signInWithEmailAndPassword(email, password){
    
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
    .catch((error) => {
      return Promise.reject(error.message || error);
    })
    .then((res: any) =>{
      
      console.log(res);      
      const user = res.user;
      console.log(user.emailVerified);
      const data: User = {
        uid: user.uid,
        email: user.email,
        //displayName: user.displayName,
        //photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        providerId: res.additionalUserInfo.providerId
      }

      this.updateUserDataNonProvider(data);
      
      return { emailVerified: user.emailVerified };
    });

  }  
  
  signOut() {
    return this.afAuth.auth.signOut();
  }
  
  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    //this.afAuth.auth.currentUser.getIdToken().then(data=>console.log(data));
    return fromPromise ( this.afAuth.auth.currentUser.getIdToken() );
  }
  
  removeSubscription(){
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${this.uid}`);
    let data = {subscriptions: { expiration_time: 0}};
    return userRef.update(data)
  }
  
  
  resetPassword(email: string) {
    //var auth = firebase.auth();
    return this.afAuth.auth.sendPasswordResetEmail(email)
      .catch((error) => console.log(error))
  }
}