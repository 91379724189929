import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//import { FlexLayoutModule } from "@angular/flex-layout";

// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';

import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';

//import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaymentService } from './payment.service';
import { UserChargesComponent } from './user-charges/user-charges.component';
//import { UserSourcesComponent } from './user-sources/user-sources.component';
//import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
//import { UserSubscriptionsComponent } from './user-subscriptions/user-subscriptions.component';
//import { ConnectRedirectComponent } from './connect-redirect/connect-redirect.component';

//Added by Tetsushi Nishida
import { PaymentFormStlComponent } from './payment-form-stl/payment-form-stl.component';
import { PricingTableComponent } from './pricing-table/pricing-table.component'; 
import { UserDefaultSourcesComponent } from './user-default-source/user-default-source.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    MatDialogModule
    //FlexLayoutModule
  ],
  declarations: [
    //PaymentFormComponent,
    UserChargesComponent, 
    //UserSourcesComponent, 
    //SubscriptionPlanComponent,
    //UserSubscriptionsComponent, 
    //ConnectRedirectComponent,
    PaymentFormStlComponent,
    PricingTableComponent,
    UserDefaultSourcesComponent,
    //StandardComponent
  ],
  exports: [
    //PaymentFormComponent, 
    UserChargesComponent, 
    //UserSourcesComponent, 
    //SubscriptionPlanComponent, 
    //UserSubscriptionsComponent, 
    //ConnectRedirectComponent,
    PaymentFormStlComponent,
    PricingTableComponent,
    UserDefaultSourcesComponent,
    //StandardComponent
  ],
  providers: [PaymentService]
})
export class PaymentModule { }
