import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { PaymentService } from '../payment.service';
import { Charge, Source } from '../models';

@Component({
  selector: 'payment-form-stl',
  templateUrl: './payment-form-stl.component.html',
  styleUrls: ['./payment-form-stl.component.css']
})
export class PaymentFormStlComponent implements AfterViewInit, OnDestroy {

  // Total amount of the charge
  @Input() totalAmount: number;

  // Emit result of operation to other components
  @Output() stripeResult = new EventEmitter<Charge | Source>();

  // Result used locacally to display status.
  result: Charge | Source;

  // The Stripe Elements Card
  @ViewChild('cardElement') cardElement: ElementRef;
  card: any;
  formError: string; 
  formComplete = false
  
  // State of async activity
  loading = false;

  constructor(private cd: ChangeDetectorRef, public pmt: PaymentService) { }

  ngAfterViewInit() {
    
    this.card = this.pmt.elements.create('card' 
      /*,{
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    }*/);
    this.card.mount(this.cardElement.nativeElement);

    // Listens to change event on the card for validation errors
    this.card.on('change', (evt) => {
      this.formError = evt.error ? evt.error.message : null
      this.formComplete = evt.complete
      this.cd.detectChanges()
    })
  }

  // Called when the user submits the form
  formHandler(): void {
    this.loading = true
    let action, customerInfo;

    if (this.totalAmount) {
      console.log(this.card);
      action = this.pmt.createChargeSteertheline(this.card, this.totalAmount);
    } else {
      
      //customerInfo = this.pmt.getCustomer();
      //customerInfo.subscribe(data => {
      //  data.sources.data.forEach(source => {
      //    console.log(source.card.fingerprint)
      //  })
      //})
      console.log(this.card);
      action = this.pmt.attachSource(this.card)
    }


    action.subscribe(
      data => {
        console.log('success');
        console.log(data);
        this.result = data;
        this.stripeResult.emit(data);
        this.loading = false;
      },
      err => {
        console.log('error');
        console.log(err)
        this.result = err;
        this.loading = false;
      }
    );

  }

  

  ngOnDestroy() {
    this.card.destroy();
  }

}
