import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../auth/firebase-auth.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  
  email: string;
  password: string;
  displayName: string;
  sentEmail: boolean = false;
  warningMessage: string ="";
  
  constructor(
    private _auth: FirebaseAuthService
  ) { }
  ngOnInit() {
  }
  
  register(){
    this._auth.signUpWithVerification(this.email, this.password, this.displayName)
    .then(res => {
      this.sentEmail = true;
      this._auth.signOut();
    })
    .catch(error => {
      console.log(error);
      this.warningMessage = 'The email address is already in use';
    });
  }
}
