import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router'
import { FirebaseAuthService } from './auth/firebase-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  subscription:Subscription;
  
  title = 'app';
  firstName = "";
  isSignedIn: boolean = false;
  
  constructor(
    private _auth: FirebaseAuthService,
    private _router: Router
  ){
    this.subscription = this._auth.user.subscribe(item => {
      if (item) {
        console.log(item);
        if (item.emailVerified) {
          this.isSignedIn = true;
          this.firstName = item.displayName.split(' ')[0];
        }
      }
    });
  }
  
  ngOnInit(){
  }
  
  signOut(){
    this._auth.signOut().then(()=>{
      this.isSignedIn = false;      
      this._router.navigate(['/home']);
    });
  }
}
