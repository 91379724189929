// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: "AIzaSyCK6WF8fofTY3KG2-pc2puNyB1z6_fsk_k",
    authDomain: "steertheline.firebaseapp.com",
    databaseURL: "https://steertheline.firebaseio.com",
    projectId: "steertheline",
    storageBucket: "steertheline.appspot.com",
    messagingSenderId: "463375421701"
  },

  /*
  firebase: {
    apiKey: "AIzaSyAUnlNmy3THwDeJauQzmumjqRv6S_SxYiI",
    authDomain: "steertheline-test.firebaseapp.com",
    databaseURL: "https://steertheline-test.firebaseio.com",
    projectId: "steertheline-test",
    storageBucket: "steertheline-test.appspot.com",
    messagingSenderId: "333886973578"
  },
  */
  // firebase: {
  //   apiKey: "AIzaSyCFmJPgo0QDNaknBinFIMcJcubJsjKVwNo",
  //   authDomain: "specumetrix-1049.firebaseapp.com",
  //   databaseURL: "https://specumetrix-1049.firebaseio.com",
  //   projectId: "specumetrix-1049",
  //   storageBucket: "specumetrix-1049.appspot.com",
  //   messagingSenderId: "780006173910",
  //   appId: "1:780006173910:web:92627144245cbd308c2d77"
  // },

  //functionsURL: 'https://us-central1-steertheline-test.cloudfunctions.net',
  //stripePublishable: 'pk_test_X5FJtYtayGOuWlOYqXiEmvOq'  
  functionsURL: 'https://us-central1-steertheline.cloudfunctions.net',
  stripePublishable: 'pk_test_gw0ISL6nQtmtGxWOG4rFc2uq'  
  
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
