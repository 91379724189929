import { Routes } from '@angular/router';
import { OriginalComponent } from './original/original.component';
import { HomeComponent } from './home/home.component';
import { PregameShowComponent } from './pregame-show/pregame-show.component';
import { PicksComponent } from './picks/picks.component';
import { GamesComponent } from './games/games.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TrackRecordComponent } from './track-record/track-record.component';
import { ProfileComponent } from './profile/profile.component';
import { MyPurchasesComponent } from './my-purchases/my-purchases.component';
//import { SubscriptionPageStlComponent } from './subscription-page-stl/subscription-page-stl.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { BlogComponent } from './blog/blog.component';

import { AuthGuard } from './auth/auth.guard';

export const routes: Routes =[
  
  { path: '', redirectTo: 'home', pathMatch: 'full' },    
  { path: "home", component: HomeComponent },
  { path: "pregame-show", component: PregameShowComponent },
  { path: "picks", component: PicksComponent, canActivate: [AuthGuard] },
  { path: "games", component: GamesComponent, canActivate: [AuthGuard] },
  { path: "signin", component: SigninComponent },
  { path: "signup", component: SignupComponent },  
  { path: "reset-password", component: ResetPasswordComponent },    
  { path: "track-record", component: TrackRecordComponent },  
  { path: "profile", component: ProfileComponent },    
  { path: "my-purchases", component: MyPurchasesComponent },
  //{ path: "subscription-stl", component: SubscriptionPageStlComponent },      
  { path: "subscription", component: SubscriptionComponent },        
  { path: "blog", component: BlogComponent },
  { path: "original", component: OriginalComponent }
  
  /*
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: "comp3", component: Comp3Component },
      { path: "iebd-sb", component: IebdSbComponent, canActivate: [AuthGuardService] },            
      { path: "iebd-c31", component: IebdC31Component, canActivate: [AuthGuardService]},
      { path: "cawnd", component: CawndComponent, canActivate: [AuthGuardService]},
      { path: "taylion", component: TaylionComponent, canActivate: [AuthGuardService]},      
      { path: "bs-grid-editor", component: BsGridEditorComponent }
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'signin', component: SigninComponent },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },      
      { path: 'comp2', component: Comp2Component }, 
    ]
  }
  //{ path: '**', component: Comp1Component } 
  
  */
];